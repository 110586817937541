import { ObjectManage } from '@/utils'

class User extends ObjectManage {

  constructor() {
    super({
      cache: true,
      cacheKey: 'userInfo'
    })
  }

  login = () => {

  }

  logout = () => {
    this.set({})
  }
}

export const user = new User()