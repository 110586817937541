export const theme = {
  primaryColor: '#3162C9',
  secondaryColor: '#F27B1F',
  // pageColor: '#3162C9',
  pageColor: 'radial-gradient(#0d418e, #082451)',

  successColor: '#34a853',
  warningColor: '#fbbc05',
  dangerColor: '#ea4335',

  textColor1: '#373D52',
  textColor2: '#73778E',
  textColor3: '#A1A6B6',
  textColor4: '#FFF',
}