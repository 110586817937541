import { Routes, Route, HashRouter } from 'react-router-dom'

import Index from './pages/index'
import News from './pages/news'
import Data from './pages/data'
import { Login } from './components/Login/index'
import './app.scss'

export const App = () => {
  return <Login>
    <HashRouter>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/news' element={<News />} />
        <Route path='/data' element={<Data />} />
      </Routes>
    </HashRouter>
  </Login>
}