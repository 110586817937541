import styles from './data.module.scss'
import { request } from '../utils'
import cardbg1 from './images/data-card1.png'
import cardbg2 from './images/data-card2.png'
import { Chart, Layout, Pie3d, ScrollBoard } from '@/components'
import { useMemo } from 'react'

export default function News() {

  return <Layout className={styles.page} width={1920} height={1080}>
    <img className='bg' src={require('./images/data-bg.png')} alt='' />
    <Center />
    <Layout.Item
      x={47} y={0}
      w={1826} h={148}
      container={<img src={require('./images/data-head.png')} alt='' />}
    />
    <Card title='人口资源数据库' x={43} y={109} w={517} h={306}>
      <Data1 />
    </Card>
    <Card title='人口资源数据库' x={43} y={439} w={517} h={300}>
      <Data2 />
    </Card>
    <Card title='人口资源数据库' x={43} y={759} w={517} h={284}>
      <Data3 />
    </Card>
    <Card title='人口资源数据库' x={1361} y={109} w={517} h={306}>
      <Data4 />
    </Card>
    <Card title='人口资源数据库' x={1361} y={439} w={517} h={300}>
      <Data5 />
    </Card>
    <Card title='人口资源数据库' x={1361} y={759} w={517} h={284}>
      <Data6 />
    </Card>
    <Card title='人口资源数据库' x={598} y={759} w={700} h={275} type={1}>
      <Data7 />
    </Card>
  </Layout>
}

const Card = ({
  title,
  type,
  children,
  ...props
}) => {
  return <Layout.Item {...props}>
    <div className={styles.card}>
      <Layout.Transform style={{ height: 43, width: type ? 704 : 547, marginBottom: 30 }}>
        <div className='card-head'>
          <img className='img' alt='' src={type ? cardbg2 : cardbg1} />
          <div className='title'>{title}</div>
        </div>
      </Layout.Transform>
      {children}
    </div>
  </Layout.Item>
}

const Center = () => {
  return <Layout.Item
    x={443} y={109}
    w={976} h={681}
    container={<img src={require('./images/data-center.png')} alt='' />}
  >

  </Layout.Item>
}

const Data1 = () => {

  const data = {
    pressure1: 15,
    pressure2: 30,
    pressure3: 20
  }

  const { size } = Layout.useTransform({ size: 20 })

  const option = useMemo(() => {

    const barWidth = size * 1.5

    const xAxisData = ['10-20', '10-21', '10-22'];
    const barData = [data.pressure1 || 0, data.pressure2 || 0, data.pressure3 || 0];
    const colors = [
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          { offset: 0, color: '#24c9ff' },
          { offset: 1, color: 'rgba(20, 43, 128, 0.2)' },
        ],
      },
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          { offset: 0, color: '#ffbf24' },
          { offset: 1, color: 'rgba(45, 72, 173, 0.1)' },
        ],
      },
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          { offset: 0, color: '#24c9ff' },
          { offset: 1, color: 'rgba(20, 43, 128, 0.2)' },
        ],
      }
    ]
    return {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'axis',
        formatter: '{b} : {c}',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        top: '5%'
      },
      xAxis: {
        data: xAxisData,
        axisLabel: {
          margin: 20,
          color: '#fff',
          fontSize: size / 1.5,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255, 0.06)',
            type: 'dashed'
          }
        },
      },
      yAxis: {
        axisLabel: {
          color: '#fff',
          fontSize: size / 1.5,
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255, 0.06)',
            type: 'dashed',
            width: 2
          }
        },
      },
      series: [
        // 类别1
        {
          type: 'bar',
          barWidth: barWidth,
          itemStyle: {
            color: function (params) {
              return colors[params.dataIndex];
            },
          },
          // label: {
          //   show: true,
          //   color: '#fff',
          //   fontSize: size,
          //   position: 'insideBottom',
          //   offset: [0, -50],
          //   formatter: '{c}Km'
          // },
          data: barData,
        },

        {
          z: 3,
          type: 'pictorialBar',
          data: barData,
          symbol: 'diamond',
          symbolOffset: ['-60%', '50%'],
          symbolSize: [barWidth, barWidth * 0.5],
          itemStyle: {
            color: function (params) {
              return colors[params.dataIndex].colorStops[1].color;
            },
          },
        },
        {
          z: 4,
          type: 'pictorialBar',
          data: barData,
          symbol: 'diamond',
          symbolPosition: 'end',
          symbolOffset: ['-60%', '-50%'],
          symbolSize: [barWidth, barWidth * 0.5],
          itemStyle: {
            borderWidth: 0,
            color: function (params) {
              return colors[params.dataIndex].colorStops[0].color;
            },
          },
        },
        // 类别2
        {
          type: 'bar',
          barWidth: barWidth,
          itemStyle: {
            color: function (params) {
              return colors[params.dataIndex];
            },
          },
          // label: {
          //   show: true,
          //   color: '#fff',
          //   fontSize: size,
          //   position: 'insideBottom',
          //   offset: [0, -50],
          //   formatter: '{c}Km'
          // },
          data: barData,
        },
        {
          z: 3,
          type: 'pictorialBar',
          data: barData,
          symbol: 'diamond',
          symbolOffset: ['60%', '50%'],
          symbolSize: [barWidth, barWidth * 0.5],
          itemStyle: {
            color: function (params) {
              return colors[params.dataIndex].colorStops[1].color;
            },
          },
        },
        {
          z: 4,
          type: 'pictorialBar',
          data: barData,
          symbol: 'diamond',
          symbolPosition: 'end',
          symbolOffset: ['60%', '-50%'],
          symbolSize: [barWidth, barWidth * 0.5],
          itemStyle: {
            borderWidth: 0,
            color: function (params) {
              return colors[params.dataIndex].colorStops[0].color;
            },
          },
        },
      ],
    };

  }, [data.pressure1, data.pressure2, data.pressure3, size])


  return <Chart style={{ flex: 1 }} option={option} />
}

const Data2 = () => {

  const option = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        formatter: function (params) {
          return params[0].name + ': ' + params[0].value;
        }
      },
      xAxis: {
        data: ['数据1', '数据2', '数据3', '数据4', '数据5'],
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          color: '#fff'
        }
      },
      yAxis: {
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false }
      },
      color: ['#cc0412'],
      grid: {
        top: 0,
        bottom: '10%'
      },
      series: [
        {
          name: 'hill',
          type: 'pictorialBar',
          barCategoryGap: '-130%',
          symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
          itemStyle: {
            opacity: 0.5
          },
          emphasis: {
            itemStyle: {
              opacity: 1
            }
          },
          data: [123, 60, 25, 18, 12],
          z: 10
        },
        {
          name: 'glyph',
          type: 'pictorialBar',
          barGap: '-100%',
          symbolPosition: 'end',
          symbolSize: 50,
          symbolOffset: [0, '-120%'],
          data: [
            {
              value: 123,
              symbolSize: 0
            },
            {
              value: 60,
              symbolSize: 0
            },
            {
              value: 25,
              symbolSize: 0
            },
            {
              value: 18,
              symbolSize: 0
            },
            {
              value: 12,
              symbolSize: 0
            }
          ]
        }
      ]
    }
  }, [])

  return <Chart style={{ flex: 1 }} option={option} />
}

const Data3 = () => {
  return <Layout.Transform style={{ paddingLeft: 24, paddingRight: 24 }}>
    <div className={styles.data3}>
      <Layout.Transform style={{ width: 164, paddingTop: 10 }}>
        <div className='item'>
          <img className='bg' alt='' src={require('./images/data-3-1.png')} />
          <Layout.Text size={22} className='value'>600/60%</Layout.Text>
          <Layout.Text size={19} className='name'>数据1</Layout.Text>
        </div>
      </Layout.Transform>
      <Layout.Transform style={{ width: 164, paddingTop: 10 }}>
        <div className='item'>
          <img className='bg' alt='' src={require('./images/data-3-2.png')} />
          <Layout.Text size={22} className='value2'>600/60%</Layout.Text>
          <Layout.Text size={19} className='name'>数据2</Layout.Text>
        </div>
      </Layout.Transform>
    </div>
  </Layout.Transform>
}

const Data4 = () => {

  const list = [
    { name: '数据名称1', value: 30, icon: require('./images/data-4-icon.png'), iconType: 1 },
    { name: '数据名称2', value: 30, icon: require('./images/data-4-1.png') },
    { name: '数据名称3', value: 30, icon: require('./images/data-4-2.png') },
    { name: '数据名称3', value: 30, icon: require('./images/data-4-icon.png'), iconType: 1 },
    { name: '数据名称4', value: 30, icon: require('./images/data-4-3.png') },
    { name: '数据名称5', value: 30, icon: require('./images/data-4-4.png') },

  ]

  return <Layout.Transform style={{ gap: 12, paddingTop: 20, paddingBottom: 20 }}>
    <div className={styles.data4}>
      {
        list.map(item => <Layout.Transform key={item.name} style={{ height: 88 }}>
          <div className='item'>
            <img className='icon' src={item.icon} alt='' />
            <Layout.Transform style={item.iconType ? { marginLeft: -26 } : {}}>
              <div className='val'>
                <Layout.Text className='value' size={28}>{item.value}</Layout.Text>
                <Layout.Text className='name' size={14}>{item.name}</Layout.Text>
              </div>
            </Layout.Transform>
          </div>
        </Layout.Transform>)
      }
    </div>
  </Layout.Transform>
}

const Data5 = () => {

  // const [res] = MagicData.useInfo('service_work_cate')

  const res = [
    {
      name: '数据1',
      value: 48
    },
    {
      name: '数据2',
      value: 95
    },
    {
      name: '数据3',
      value: 55
    },
    {
      name: '数据4',
      value: 15
    }
  ]

  const pieData = useMemo(() => {
    const colors = ['#ff0023', '#fd9a76', '#c5ed7f', '#fc264d']
    return res.map?.((item, index) => {
      return {
        name: item.name,
        value: +item.value,
        itemStyle: {
          color: colors[index],
          opacity: 0.8
        },
      }
    }) || []
  }, [])

  const scrollData = useMemo(() => {
    const count = pieData.reduce((prev, current) => prev + current.value, 0)
    const data = pieData.map(item => [
      `<div class="pie-chart-data-index"><div style="background-color: ${item.itemStyle.color};"></div></div>`,
      item.name,
      (item.value / count * 100).toFixed(2) + '%',
      // item.value
    ])
    return {
      data: data,
      align: ['center', 'center', 'center', 'center'],
      evenRowBGC: 'transparent',
      oddRowBGC: 'transparent',
      rowNum: 5,
    }
  }, [pieData])

  return <div className={styles.data5}>
    <Layout.Transform style={{ width: 175, height: 165 }}>
      {!!pieData.length && <ScrollBoard config={scrollData} />}
    </Layout.Transform>
    <Layout.Transform style={{ width: 320, height: 158 }}>
      {!!pieData.length && <Pie3d className='pie-chart' option={pieData} />}
    </Layout.Transform>
  </div>
}

const Data6 = () => {

  const data = [
    { value1: 48, value2: 24, value3: 15 },
    { value1: 56, value2: 28, value3: 15 },
    { value1: 22, value2: 27, value3: 35 },
    { value1: 33, value2: 15, value3: 44 },
    { value1: 44, value2: 36, value3: 8 },
    { value1: 24, value2: 33, value3: 36 },
    { value1: 40, value2: 24, value3: 44 }
  ]

  const option = useMemo(() => ({
    color: ['#e93232', '#e59900', '#eb9e00'],
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      top: '5%',
      left: '6%',
      right: '6%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map((item, index) => index + 1) || [],
      axisLabel: {
        color: '#fff'
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255, 0.06)'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255, 0.06)',
          width: 2
        }
      },
      axisLabel: {
        color: '#fff'
      },
    },
    series: [
      {
        name: '数据1',
        type: 'line',
        stack: 'Total',
        data: data.map(item => item.value1) || []
      },
      {
        name: '数据2',
        type: 'line',
        stack: 'Total',
        data: data.map(item => item.value2) || []
      },
      {
        name: '数据3',
        type: 'line',
        stack: 'Total',
        data: data.map(item => item.value3) || []
      },
    ]
  }), [])

  return <Chart style={{ flex: 1 }} option={option} />
}

const Data7 = () => {

  const data = [
    ['内容', '2023-03-12 14:11:10', '这里是预警内容', '2023-03-12 14:11:10'],
    ['内容', '2023-03-12 14:11:10', '这里是预警内容', '2023-03-12 14:11:10'],
    ['内容', '2023-03-12 14:11:10', '这里是预警内容', '2023-03-12 14:11:10'],
    ['内容', '2023-03-12 14:11:10', '这里是预警内容', '2023-03-12 14:11:10'],
    ['内容', '2023-03-12 14:11:10', '这里是预警内容', '2023-03-12 14:11:10']
  ]

  // const { width } = Layout.useTransform({ width: 700 })
  // const itemSize = width / 12

  const config = useMemo(() => ({
    header: ['表头', '时间', '风险预警', '时间'],
    data: data,
    // columnWidth: [itemSize, itemSize, itemSize * 7, itemSize, itemSize, itemSize],
    align: ['center', 'center', 'center', 'center', 'center', 'center'],
    evenRowBGC: '#990d12',
    oddRowBGC: 'transparent',
    headerBGC: '#990d12',
    rowNum: 4,
  }), [])

  return <div className={styles.data7}>
    <ScrollBoard style={{ flex: 1 }} config={config} />
  </div>
}