import { asyncTimeOut } from '@/utils'
import anime from 'animejs'
import { useEffect, useState } from 'react'

const View = ({
  children
}) => {

  const [show, setShow] = useState(false)

  useEffect(() => {
    StaticManage.start().then(() => {

    }).catch(err => {
      console.log('资源加载失败', err)
    }).finally(() => {
      setShow(true)
    })
  }, [])

  return show ? children : null
}

export class StaticManage {

  static tasks = []

  static loadScript = url => {
    return () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript';
        script.onload = () => {
          resolve()
        }
        script.onerror = err => {
          reject(err)
        }
        script.src = url
        document.getElementsByTagName('head')[0].appendChild(script)
      })
    }
  }

  static loadCss = url => {
    return () => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.onload = () => {
          resolve()
        }
        link.onerror = reject
        link.href = url
        document.getElementsByTagName('head')[0].appendChild(link)
      })
    }
  }

  static loadImage = url => {
    return () => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve()
        }
        img.onerror = reject
        img.src = url
      })
    }
  }

  static addPromise = (...promises) => {
    this.tasks.push(...promises)
  }

  static addScript = (...urls) => {
    this.addPromise(...urls.map(this.loadScript))
  }

  static addCss = (...urls) => {
    this.addPromise(...urls.map(this.loadCss))
  }

  static addImage = (...urls) => {
    this.addPromise(...urls.filter(url => {
      if (typeof url === 'string' && url.startsWith('data:image/')) {
        return false
      }
      return true
    }).map(this.loadImage))
  }

  static async start() {
    let reult = 0
    let currentAn
    const startAn = () => {
      reult++
      currentAn?.pause?.()
      currentAn = anime({
        targets: '#loading div',
        width: reult / this.tasks.length * 100 + '%',
        duration: 300,
        easing: 'easeInOutQuad'
      })
    }
    await Promise.all(this.tasks.map(task => task().then(() => {
      startAn()
    }).catch(() => {
      startAn()
    })))
    await asyncTimeOut(600)
    anime({
      targets: '#loading',
      width: 0,
      borderRadius: 0,
      opacity: 0,
      duration: 500,
      easing: 'easeInOutQuad'
    })
    await asyncTimeOut(600)
    document.getElementById('loading')?.remove()
  }

  static View = View
}