import { useEffect, useMemo, useRef, useState } from 'react'
import { Page } from '../components'
import bg from './images/index-bg.png'
import styles from './index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { request, user } from '../utils'

import 'swiper/css'

export default function Index() {

  const swiper = useRef()

  const [list, setList] = useState([])


  const { userInfo } = user.useData()

  useEffect(() => {
    request({
      path: 'oauth/oauth'
    }).then(res => {
      setList(res.data)
    })
  }, [])

  const renderList = useMemo(() => {
    return list.reduce((prev, item, index) => {
      const i = index / 4 | 0
      if (!prev[i]) {
        prev[i] = []
      }
      prev[i].push(item)
      return prev
    }, [])
  }, [list])

  return <Page className={styles.page}>
    <img src={bg} alt='' className={styles.bg} />
    <div className={styles.title}>鄂尔多斯市智慧石榴籽平台</div>
    <div className={styles.user}>
      <div className='name'>当前用户：{userInfo?.username}</div>
      <div className='logout' onClick={user.logout}>退出</div>
    </div>
    <Swiper
      // onSlideChange={() => console.log('slide change')}
      onSwiper={e => swiper.current = e}
    >
      {
        renderList.map((group, gi) => <SwiperSlide key={gi}>
          <div className={styles.list}>
            {
              group.map(item => <Item key={item.name} {...item} />)
            }
          </div>
        </SwiperSlide>)
      }
      <img className='nav left' alt='' src={require('./images/index-nav1.png')}
        onClick={() => swiper.current.slidePrev()}
      />
      <img className='nav right' alt='' src={require('./images/index-nav2.png')}
        onClick={() => swiper.current.slideNext()}
      />
    </Swiper>
  </Page>
}

const Item = ({
  icon,
  title,
  url
}) => {

  return <div className='item' onClick={() => window.open(url)}>
    <img alt='' src={icon} className='bg' />
    <div className='name'>{title}</div>
  </div>
}