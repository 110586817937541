export const weather = {
  async request(adcode, extensions) {
    const res = await fetch(`https://restapi.amap.com/v3/weather/weatherInfo?key=e5d995b6d4d3c5c455eff8d64ce24619&city=${adcode}&extensions=${extensions}`)
    if (res.status === 200) {
      try {
        const data = await res.json()
        if (data.infocode === '10000') {
          return data
        } else {
          // eslint-disable-next-line no-throw-literal
          throw {
            code: res.status,
            message: data.info
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-throw-literal
        throw {
          code: error?.code,
          message: error?.message,
          ...error
        }
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        code: res.status,
        message: ''
      }
    }
  },
  async city(adcode) {
    const res = await this.request(adcode, 'base')
    return res.lives[0]
  },
  async future(adcode) {
    const res = await this.request(adcode, 'all')
    return res.forecasts[0].casts
  }
}