import classNames from 'classnames'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import anime from 'animejs'
import { systemInfo } from '@/utils'
import { theme } from '../config/theme'
import styles from './Layout.module.scss'
import closeIcon from './images/close.png'

const context = createContext({
  width: 1920,
  height: 1080,
  addElement: () => ({ update: () => null, remove: () => null })
})


export const Layout = ({
  width = 1920,
  height = 1080,
  className,
  style,
  children,
  ...props
}) => {

  const [eles, setEles] = useState([])

  const addElement = useCallback(ele => {
    const item = { ele }
    setEles(old => [...old, item])
    return {
      update: e => {
        setEles(old => {
          const index = old.indexOf(item)
          if (~index) {
            old[index].ele = e
            return [...old]
          }
          return old
        })
      },
      remove: () => {
        setEles(old => {
          const index = old.indexOf(item)
          if (~index) {
            old.splice(index, 1)
            return [...old]
          }
          return old
        })
      }
    }
  }, [])

  return <context.Provider value={{ width, height, addElement }}>
    <div className={classNames(styles.layout, className)} style={{ background: theme.pageColor, ...style }} {...props}>
      {children}
      {
        eles.map(item => item.ele)
      }
    </div>
  </context.Provider>
}

let itemid = 0

const LayoutItem = ({
  x,
  y,
  w,
  h,
  style,
  container,
  containerProps,
  zoom,
  ...props
}) => {

  const id = useMemo(() => 'layout-item-' + ++itemid, [])

  const { width, height } = useContext(context)

  const [modal, setModal] = useState(false)

  const closeRef = useRef(null)
  // 缩放大小
  const scale = 1.6

  const _style = useMemo(() => {
    const s = {
      position: 'absolute',
      left: x / width * 100 + '%',
      top: y / height * 100 + '%',
      transformOrigin: '0 0'
    }
    if (w) {
      s.width = w / width * 100 + '%'
    }
    if (h) {
      s.height = h / height * 100 + '%'
    }
    return s
  }, [height, h, width, w, x, y])

  const open = useCallback(() => {
    if (!zoom) {
      return
    }
    if (modal) {
      return
    }
    const eleid = `#${id}`
    const ele = document.querySelector(eleid)
    ele.style.zIndex = 999
    anime({
      targets: eleid,
      left: (width - w * scale) / 2 / width * 100 + '%',
      top: (height - h * scale) / 2 / height * 100 + '%',
      scale,
      easing: 'easeOutQuint',
      duration: 400
    })
    setModal(true)
  }, [zoom, modal, id, width, w, height, h])

  const close = useCallback(() => {
    const eleid = `#${id}`
    const ele = document.querySelector(eleid)
    anime({
      targets: eleid,
      left: x / width * 100 + '%',
      top: y / height * 100 + '%',
      scale: 1,
      easing: 'easeOutQuint',
      duration: 400
    })
    // 清除层级
    setTimeout(() => {
      ele.style.zIndex = 'unset'
    }, 400)
    setModal(false)
  }, [height, id, width, x, y])

  useEffect(() => {
    if (modal) {
      anime({
        targets: closeRef.current,
        opacity: 1,
        easing: 'easeOutQuint',
        duration: 400
      })
    }
  }, [modal])

  let child
  if (container) {
    if (React.isValidElement(container)) {
      child = React.cloneElement(container, {
        style: { ...style, ..._style, ...container.props.style },
        ...props,
        id,
        onClick: e => {
          props.onClick?.(e)
          container.props.onClick?.(e)
          open()
        },
      })
    } else {
      const Comp = container
      child = <Comp style={{ ...style, ..._style }} {...props} {...containerProps}
        id={id}
        onClick={e => {
          open()
          props.onClick?.(e)
          containerProps?.onClick?.(e)
        }}
      />
    }
  } else {
    child = <div style={{ ...style, ..._style }} {...props}
      id={id}
      onClick={e => {
        open()
        props.onClick?.(e)
      }}
    ></div>
  }
  return <>
    {modal && <div className={styles.zoomMask} onClick={close}></div>}
    {child}
    {modal && <Layout.Transform style={{ width: 80 }}>
      <div className={styles.zoomMaskClose}
        ref={closeRef}
        onClick={close}
        style={{
          left: (width - (width - w * scale) / 2) / width * 100 + '%',
          top: (height - h * scale) / 2 / height * 100 + '%'
        }}
      >
        <img alt='' src={closeIcon} />
      </div>
    </Layout.Transform>}
  </>
}

const Text = ({
  size = 16,
  style,
  ...props
}) => {

  const { windowWidth } = systemInfo.useInfo()

  const { width } = useContext(context)

  const scal = width / windowWidth

  return <span style={{ ...style, fontSize: size / scal }}  {...props} />
}

const heightKeys = ['height', 'top', 'bottom', 'marginTop', 'marginBottom', 'paddingTop', 'paddingBottom']

const transform = (style, wscal, hscal) => {
  if (style) {
    return Object.fromEntries(Object.keys(style).map(key => {
      if(typeof style[key] === 'undefined') {
        return undefined
      }
      if (heightKeys.includes(key)) {
        return [key, style[key] / hscal]
      } else {
        return [key, style[key] / wscal]
      }
    }).filter(v => v))
  }
  return {}
}

const Transform = ({
  style,
  children
}) => {

  const { windowWidth, windowHeight } = systemInfo.useInfo()

  const { width, height } = useContext(context)

  const wscal = width / windowWidth
  const hscal = height / windowHeight

  const _style = useMemo(() => {
    return transform(style, wscal, hscal)
  }, [hscal, style, wscal])

  if (!children) {
    return
  }

  return React.cloneElement(children, {
    style: { ..._style, ...children.props.style }
  })
}

const useTransform = style => {
  const { windowWidth, windowHeight } = systemInfo.useInfo()

  const { width, height } = useContext(context)

  const wscal = width / windowWidth
  const hscal = height / windowHeight

  return transform(style, wscal, hscal)
}

const Modal = ({
  show,
  children,
  onClose
}) => {

  const { addElement } = useContext(context)

  const mask = useRef(null)
  const ref = useRef(null)

  const [selfShow, setSelfShow] = useState(show)

  useMemo(() => {
    if (show) {
      setSelfShow(true)
    }
  }, [show])

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        anime({
          targets: mask.current,
          opacity: 1,
          duration: 400
        })
        anime({
          targets: ref.current,
          opacity: 1,
          scale: 1,
          translateX: '-50%',
          translateY: '-50%',
          easing: 'easeOutQuint',
          duration: 400
        })
      }, 20)
    } else {
      if (!mask.current) {
        return
      }
      anime({
        targets: mask.current,
        opacity: 0,
        duration: 400
      })
      anime({
        targets: ref.current,
        opacity: 0,
        scale: 0,
        translateX: '-50%',
        translateY: '-50%',
        easing: 'easeOutQuint',
        duration: 400
      })
      const timer = setTimeout(() => {
        mask.current = null
        ref.current = null
        setSelfShow(false)
      }, 400)
      return () => clearTimeout(timer)
    }
  }, [show])

  const action = useMemo(() => addElement(null), [addElement])

  useMemo(() => {
    const ele = selfShow ? <>
      <div ref={mask} className={styles.modalMask}
        onClick={() => onClose?.()}
      />
      <div ref={ref} className={styles.modal}>
        {children}
      </div>
    </> : null
    action.update(ele)
  }, [action, children, onClose, selfShow])

  useEffect(() => {
    return () => action.remove()
  }, [action])
}

Layout.Item = LayoutItem
Layout.Text = Text
Layout.Transform = Transform
Layout.useTransform = useTransform
Layout.Modal = Modal