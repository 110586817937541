import * as echarts from 'echarts'
import 'echarts-gl'
import 'echarts-liquidfill'
import { useEffect, useRef } from 'react'
import { systemInfo } from '@/utils/systemInfo'
import styles from './Chart.module.scss'
import classNames from 'classnames'
import anime from 'animejs'
import { loopShowTooltip } from './autoTooltip'


export const Chart = ({ option, components, className, onInit, loopTooltip = true, ...props }) => {

  const main = useRef(null)

  const chart = useRef(null)

  const { windowHeight, windowWidth } = systemInfo.useInfo()

  const onInitRef = useRef(null)
  onInitRef.current = onInit

  useEffect(() => {
    chart.current = echarts.init(main.current, null, {
      // width: main.current.offsetWidth,
      // height: main.current.offsetHeight,
      renderer: 'canvas',
      useDirtyRect: false
    })
    onInitRef.current?.(chart.current)
    return () => {
      chart.current.dispose()
    }
  }, [])

  useEffect(() => {
    // console.log(main.current.offsetWidth, main.current.offsetHeight)
    chart.current?.resize({
      width: main.current.offsetWidth,
      height: main.current.offsetHeight
    })
  }, [windowHeight, windowWidth])

  useEffect(() => {
    if (option) {
      chart.current.setOption(option)
      if (loopTooltip) {
        const { clearLoop } = loopShowTooltip(chart.current, option)
        return () => {
          clearLoop()
        }
      }
    }
  }, [option, loopTooltip])

  return <div {...props} className={classNames(styles.Chart, className)}>
    <div ref={main} className='abs'>
    </div>
  </div>
}

Chart.randomData = data => {
  return data.map(item => {
    item.value = item.value + Math.min(100, anime.random(1, Math.max(item.value / 10 | 0, 2)))
    return item
  })

}