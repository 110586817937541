import { Page } from '../components'
import styles from './news.module.scss'
import { request } from '../utils'

export default function News() {

  return <Page className={styles.page}>
    <div className='head' />
    <div className='main'>
      <div className='left'>
        <div className='item select'>民族团结进步创建数据库</div>
        <div className='item'>人口资源数据库</div>
        <div className='item'>民族团结进步创建数据库</div>
        <div className='item'>人口资源数据库</div>
      </div>
      <div className='right'>
        <div className='top'></div>
        <div className='content'></div>
      </div>
    </div>
  </Page>
}
